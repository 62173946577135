@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'draft-js/dist/Draft.css';
@import './editorStyles.css';





body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input::placeholder {
  color: rgb(170, 169, 169);
}

.public-DraftEditorPlaceholder-inner {
  position: absolute;
  color: rgb(170, 169, 169);
  pointer-events: none;
}

.inputTextPlaceholder::placeholder{
  color: rgb(170, 169, 169);
}
.inputTextPlaceholder{
  color: rgb(170, 169, 169);
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.modal-content {
  @apply fixed absolute z-50 h-auto overflow-hidden transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border p-1 rounded-lg bg-indigo-50;
  @apply w-2/5; /* width 40% on larger screens */
}

/* Full width on small screens (mobile) */
@media (max-width: 640px) {
  .modal-content {
    @apply w-full; 
  }
}

.reactEasyCrop_Container {
  @apply rounded-lg
}

.reactEasyCrop_CropArea {
  @apply rounded-lg
}

li > p {
  @apply inline mb-2;
}